import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { CMSContext } from "@";
import CtreesLogo from "../../assets/ctrees-sm-logo.png";
import { getArticles } from "./helpers";
import { LoadingAnimation } from "../../components";

const isTokenValid = (token) => {
    if (!token) return false;
    try {
        const decoded = jwtDecode(token);
        return decoded.exp * 1000 > Date.now();
    } catch (error) {
        return false;
    }
};

const confirmUser = (token) => {
    fetch(
        `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/user/confirm`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        },
    )
        .then((resp) => {
            if (!resp.ok) {
                throw new Error(`HTTP error! status: ${resp.status}`);
            }
            return resp.json();
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
        });
};

const isUserAdmin = async (token) => {
    let isAdmin = false;
    const checkPermissions = await getArticles(token);
    isAdmin = !!checkPermissions;

    return isAdmin;
};

const Login = ({ children }) => {
    const {
        isAuthenticated,
        user,
        getAccessTokenSilently,
        loginWithRedirect,
        getIdTokenClaims,
    } = useAuth0();
    const [isLoaded, setIsLoaded] = useState(false);
    const { pathname } = window.location;
    const { setState } = useContext(CMSContext);
    const isCMS = pathname === "/admin";

    const redirectUserToLogin = () => {
        loginWithRedirect({
            appState: { returnTo: pathname }, // Save current pathname in appState
        });
        return;
    };

    useEffect(() => {
        const storeToken = async () => {
            const tokenStorage = localStorage.getItem("token");

            // LIKELY REMOVE THIS
            if (isTokenValid(tokenStorage)) {
                console.log("Token is valid");
                setIsLoaded(false);
            }

            // Ensure user is authenticated before attempting to retrieve a token
            if (!isAuthenticated && !user && !tokenStorage) {
                redirectUserToLogin();
                return;
            }

            if (isAuthenticated && user) {
                console.log(isAuthenticated, user);
                try {
                    // Check to see if user exists
                    const idTokenClaims = await getIdTokenClaims();
                    const token = idTokenClaims.__raw; // Assuming profile is in JWT format
                    localStorage.setItem("token", token);

                    // Gets CMS articles using token to see if user has admin permissions
                    let isAdmin = false;
                    if (token) {
                        isAdmin = await isUserAdmin(token);

                        confirmUser(token);
                        localStorage.setItem("isAdmin", isAdmin);
                    }

                    setState({ token, isAdmin });
                    setIsLoaded(true);
                } catch (error) {
                    console.error("Error storing token:", error);
                }
            } else if (!isTokenValid(tokenStorage)) {
                console.log("Token is invalid");
                localStorage.removeItem("token");
                localStorage.removeItem("isAdmin");

                if (isCMS) {
                    redirectUserToLogin();
                    return;
                } else {
                    console.log("Attempting to retrieve new token");
                    try {
                        // Attempt to retrieve a new token silently
                        const newToken = await getAccessTokenSilently();
                        if (newToken) {
                            let isAdmin = await isUserAdmin(newToken);
                            confirmUser(newToken);

                            localStorage.setItem("token", newToken);
                            localStorage.setItem("isAdmin", isAdmin);

                            setState({
                                token: newToken,
                                isAdmin: isAdmin,
                            });
                            setIsLoaded(true);
                        } else {
                            throw new Error("Unable to retrieve new token");
                        }
                    } catch (error) {
                        console.error("Error retrieving new token:", error);
                        // Redirect to login page if unable to retrieve new token
                        redirectUserToLogin();
                    }
                }
            }
        };

        storeToken();
    }, [isAuthenticated, user, getAccessTokenSilently]);

    const handleLoginClick = () => {
        redirectUserToLogin();
    };

    if (isAuthenticated) {
        return children;
    } else {
        return (
            <>
                <div className="blur-sm">{children}</div>

                <div className={promptToLogin}>
                    {isLoaded ? (
                        <div className={messageContainer}>
                            <img src={CtreesLogo} />
                            <button
                                className={buttonToLogin}
                                onClick={handleLoginClick}
                            >
                                Click here to login.
                            </button>
                            <p className="text-sm">
                                You will be redirected to our secure login page.
                                After logging in, you'll be automatically
                                redirected back to continue where you left off.
                            </p>
                        </div>
                    ) : (
                        <div className={"h-auto w-96"}>
                            <LoadingAnimation />
                        </div>
                    )}
                </div>
            </>
        );
    }
};

const promptToLogin =
    "fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50";
const messageContainer =
    "flex flex-col justify-center items-center w-96 space-y-4 rounded-md bg-white p-6 shadow-md";
const buttonToLogin =
    "bg-black hover:bg-gray text-white font-bold py-2 px-4 rounded";

export default Login;
