import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

export default function ActivityMap() {
  const [loading, setLoading] = useState(true);
  return (
    <div className="w-full h-navscreen mt-[80px]">
      {loading && (
        <div>
          <Skeleton className="h-[50px] mb-4" count={1} />
          <div className="flex">
          <Skeleton className="h-[100px] ml-10 !w-[300px]" count={5} />
          <Skeleton className="h-[515px] ml-10 !w-[700px]" />
          </div>
        </div>
      )}
      <iframe
        src={process.env.REACT_APP_ACTIVITY_URL}
        className={containerStyle}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
}

const containerStyle = clsx(
    `fixed left-0 top-0 z-40 h-screen w-full lg:relative lg:mt-[80px] lg:h-navscreen`,
);
